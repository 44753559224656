import React from 'react'
import Highlight, {defaultProps} from 'prism-react-renderer'
import Prism from 'prismjs'
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-yaml'
import { includes, get, concat } from 'lodash'

Prism.languages['dashbot-js'] = Prism.languages.extend('javascript', {
  'string': {
    pattern: /(["'])(?:\\(?:\r\n|[\s\S])|(?!\1)[^\\\r\n])*\1/,
    greedy: true,
    inside: {
      'strong-tag': /(<strong>|<\/strong>)/,
    }
  }
});
Prism.languages.insertBefore('dashbot-js', 'string', {
  'strong-tag': /(<strong>|<\/strong>)/
})

Prism.languages['dashbot-shell'] = Prism.languages.extend('bash', {
  'string': concat(Prism.languages.bash.string, {
    pattern: /(^|[^\\](?:\\\\)*)(["'])(?:\\[\s\S]|\$\([^)]+\)|\$(?!\()|`[^`]+`|(?!\2)[^\\`$])*\2/,
    greedy: true,
    inside: {
      'strong-tag': /(<strong>|<\/strong>)/
    }
  })
});
Prism.languages.insertBefore('dashbot-shell', 'string', {
  'strong-tag': /(<strong>|<\/strong>)/
})

Prism.languages['dashbot-py'] = Prism.languages.extend('python', {});
Prism.languages.insertBefore('dashbot-py', 'string', {
  'strong-tag': /(<strong>|<\/strong>)/
})

Prism.languages['dashbot-ruby'] = Prism.languages.extend('ruby', {
  'string': {
    pattern: /(["'])(?:\\(?:\r\n|[\s\S])|(?!\1)[^\\\r\n])*\1/,
    greedy: true,
    inside: {
      'strong-tag': /(<strong>|<\/strong>)/,
    }
  }
});

Prism.languages['dashbot-json'] = Prism.languages.extend('json', {});
Prism.languages.insertBefore('dashbot-json', 'string', {
  'strong-tag': /(<strong>|<\/strong>)/
})

Prism.languages['dashbot-yaml'] = Prism.languages.extend('yaml', {});
Prism.languages.insertBefore('dashbot-yaml', 'string', {
  'strong-tag': /(<strong>|<\/strong>)/
})

export default ({children, className}) => {
  const language = className ? className.replace(/language-/, '') : '';
  let modify = false;

  return (
    <Highlight {...defaultProps} Prism={Prism} code={children} language={language}>
      {
        ({className, style, tokens, getLineProps, getTokenProps}) => {
          return (
            <pre className={className} style={{...style, padding: '20px'}}>
            {
              tokens.map((line, i) => {
                if (line.length === 1 && tokens.length - 1 === i && !get(line, '0.content')) {
                  return null;
                }
                return (
                  <div key={i} {...getLineProps({line, key: i})}>
                    {line.map((token, key) => {
                      if (includes(token.types, 'strong-tag')) {
                        modify = !modify;
                        return null;
                      }

                      if (modify) {
                        const modifiedProps = getTokenProps({token, key});
                        modifiedProps.style = { fontWeight: 'bolder', color: '#fff', backgroundColor: '#ff5d22'}
                        return (
                          <span key={key} {...modifiedProps}/>
                        )
                      }

                      return (<span key = {key} {...getTokenProps({token, key})} />)
                    })}
                  </div>
                )
              })
            }
          </pre>
          )
        }
    }

    </Highlight>
  )
}
