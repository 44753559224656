import React from "react"
import { MDXProvider } from "@mdx-js/react"
import styles from "./mdx-provider.module.scss"
import { Image } from "react-bootstrap"
import Code from './code-blocks'

const h1 = props => (
  <h1 className={styles.h1} {...props}>
    {props.children}
  </h1>
)
const p = props => (
  <p className={styles.p} {...props}>
    {props.children}
  </p>
)
const h2 = props => (
  <h2 className={styles.h2} {...props}>
    {props.children}
  </h2>
)
const h3 = props => (
  <h3 className={styles.h3} {...props}>
    {props.children}
  </h3>
)
const img = props => <Image rounded className={styles.img} {...props} alt={`page image`} />
const code = props => (
  <code className={styles.code} {...props}>
    {props.children}
  </code>
)


const iframe = props => (
  <div className={styles.iframe}>
    <div className={styles.container}>
      <iframe className={styles.video} {...props}>
        {props.children}
      </iframe>
    </div>
  </div>
)

export default function Layout({ children }) {
  return (
    <MDXProvider
      components={{
        // Map HTML element tag to React component
        h1: h1,
        h2: h2,
        h3: h3,
        p: p,
        img: img,
        iframe: iframe,
        code: Code,
      }}
    >
      {children}
    </MDXProvider>
  )
}
